<template>
  <BaseDialog :model-value="true" size-class="!max-w-lg" @update:model-value="$emit('close')">
    <template #header><span class="px-2 text-base">Messenger is Live!</span></template>
    <template #default>
      <div class="flex flex-col space-y-3 px-2 py-3 text-gray-300">
        <span class="pt-2">
          Great news! Messenger, our new messaging product, has just launched on the Terminal. Enable Messenger for your
          team to facilitate seamless communication among your team and other teams on the platform.
        </span>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="md" @press="$emit('close')">Cancel</BaseButton>
        <BaseButton size="md" @press="enableMessenger">Enable</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { inject, computed } from 'vue';
const $emit = defineEmits(['close']);
const $http = inject('http');
async function enableMessenger() {
  await $http.patch('/chat/enable_messaging');
  $emit('close');
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}
</script>
